/** @jsxImportSource @emotion/react */
import { HomeLayout } from './HomeLayout'
import { NewStake } from '../../components/Home/NewStake'

export const HomeStake = () => {
  return (
    <HomeLayout>
      <NewStake />
    </HomeLayout>
  )
}
