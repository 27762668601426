export enum ChainId {
  MAINNET = 1,
  BSC_TEST = 97,
  SEPOLIA = 11155111,
  BASE = 8453,
}

// export const TEST_RPC_NODE =
// 'https://dry-little-tent.bsc-testnet.discover.quiknode.pro/84b8cf011376495b8a07b91c5e3868de9e58f08e/'
// 'https://rpc.ankr.com/bsc_testnet_chapel/128bdedab70a53096c6b5132d94384254aee84b8491502b928ab6c08652a7b78'
//
// export const TEST_RPC_NODE = 'https://rpc.ankr.com/bsc_testnet_chapel'
//
export const TEST_RPC_NODE = 'https://base-mainnet.g.alchemy.com/v2/hHGvPWEXI0L1TyBsSxrHedZpV1jpk0Fn'

export const LINK_ADDRESS = '0xC9EbC2469E403DD89eAcA78C6B0b216fc7501011'

export const NODE_ADDRESS = ['0x2B0DaBAC8437672F2f8c3b4981F17F7F88173e56']

export const K_LINE_API = 'https://base-api.krav.trade/krav/v1/klines?symbol=BTCUSDT&interval=1d'

export const QUANTO_API = 'https://base-api.krav.trade/krav/v1/list/quanto'

export const TRADE_HISTORY_API = 'https://base-api.krav.trade/krav/v1/list/market'

export const DASHBOARD_OVERVIEW_API = 'https://base-api.krav.trade/krav/v1/overview'

export const TEST_CHAIN_ID = ChainId.BASE

export const BTC_PRICE_API = 'https://base-api.krav.trade/krav/v1/ticker?symbol='

export const KRAV_STAKE = '0xe480d334e6BF7693b12982e9Bf116F3BEeD386a0'

export const KRAV_ADDRESS = '0xbE3111856e4acA828593274eA6872f27968C8DD6'

export const DashBoard_USER_OVERVIEW_API = 'https://base-api.krav.trade/krav/v1/user/asset/amount?account='

export const BASE_KRAV_TRADING_ADDRESS = '0x8975Fdbad4884998AC36669d126471cE239D94b1'

export const VE_KRAV = '0xDeE06ff0dBE3eBFD05b9E54B4ea228eC0FbD7f71'

export const FEE_DISTRBUTOR = '0x37170e7f0045C3DDe99F8884d9B6E2322697CC74'

export const LP_REWARD_API = 'https://test-api.krav.trade/krav/v1/miner/'

export const LP_REWARD_CONTRACT = '0x2e2dAb2d3f4dFb9F39ec6A11A0abb34fa7D27A19'

export const TRADE_REWARD_CONTRACT = '0x291182b2F0108E666e79b8fBC7c7b67C502564d7'
